'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/users', {
        templateUrl: 'app/users/users.html',
        controller: 'UsersController',
        hasPermissions: ['USERS:GET']
        //hasPermissions: ['USERS:GET', 'USERS:PUT', 'USERS:DELETE']
    }).when('/users/add', {
        templateUrl: 'app/users/add.edit.html',
        controller: 'UsersController'
    }).when('/users/edit/:id', {
        templateUrl: 'app/users/add.edit.html',
        controller: 'UsersController',
        hasPermissions: ['USERS:PUT', 'USERS:DELETE']
    });
});
